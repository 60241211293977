import React, { useState, useEffect, useContext } from "react"

import { GET_CONFIG_DATA } from "../../components/shared-actions/get-config-data"
import { COMPANY_DETAIL_FETCH } from "../../components/company-profile/actions/detailActions"

import { COMPANY_STEP_MENU } from "../../constants/profile-menu"
import { COMPANY_SIDEBAR_MENU_STEP } from "../../constants/sidebar-menu"

import { GlobalAuthContext } from "../../context/authContext"

import CompanyProfileLayout from "../../components/layout/company-profile"
import CompanyDetailForm from "../../components/company-profile/form/company-detail-form"

const CompanyDetailPage = ({ location }) => {
  const [companyDetailFormData, setCompanyDetailFormData] = useState({
    organization_name: "",
    year_of_establishment: "",
    no_of_employees: "",
    industry: "",
    country: "",
    state: "",
    city: "",
    address: "",
    email: "",
    website: "",
    phone: "",
    landline: "",
    about: "",
  })

  const [configData, setConfigData] = useState({})

  const { state, dispatch } = useContext(GlobalAuthContext)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchCompanyDetail = async () => {
      try {
        if (state && state.user && state.user.role === 3) {
          await COMPANY_DETAIL_FETCH(
            dispatch,
            setCompanyDetailFormData,
            companyDetailFormData,
          )
          setIsLoading(false);
          if (!state.config) {
            await GET_CONFIG_DATA(setConfigData, dispatch)
          } else {
            setConfigData(state.config)
          }
        }
      } catch (error) { }
    }
    fetchCompanyDetail();
  }, [state.user, state?.config]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CompanyProfileLayout
      title="Company Detail"
      menu={COMPANY_STEP_MENU}
      sidebarMenu={COMPANY_SIDEBAR_MENU_STEP}
      activeSidebar="Edit Profile"
      active="Company Detail"
      location={location}
    >
      <CompanyDetailForm
        isLoading={isLoading}
        companyDetailFormData={companyDetailFormData}
        configData={configData}
        setCompanyDetailFormData={setCompanyDetailFormData}
      />
    </CompanyProfileLayout>
  )
}

export default CompanyDetailPage
