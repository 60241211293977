import React, { useState, useEffect, useContext } from "react"

import { Row, Col } from "react-bootstrap"

import Warning from "../../../images/icons/wdn-warning.png"

import {
  COMPANY_DETAIL_FORM_SUBMIT,
  COMPANY_COUNTRY_FETCH,
} from "../actions/detailActions"

import "./company-detail-form.scss"
import Spinner from "../../spinner/spinner"
import TextArea from "../../input/textarea"
import InputBox from "../../input/wdn-input-box"
import InputTextBox from "../../input/input-text-box"
import ProfileImageUploader from "../../profile-image/profile-image-uploader"
import { GlobalAuthContext } from "../../../context/authContext"
import { COMPANY_PROFILE_SUBMIT } from "../actions/socialAction"
import Social from "../../social"
import { selectValueFromId } from "../../../utils/format-skills-for-select"
import TagInput from "../../input/tag-input"

const CompanyDetailForm = props => {
  const {
    companyDetailFormData,
    setCompanyDetailFormData,
    isLoading,
    configData,
  } = props

  const [city, setCity] = useState([])
  const [state, setState] = useState([])
  const [errors, setErrors] = useState({})

  const [isProcessing, setIsProcessing] = useState(false)
  const stateValue = useContext(GlobalAuthContext)

  const handleCompanyNameChange = value => {
    setCompanyDetailFormData({
      ...companyDetailFormData,
      organization_name: value,
    })
  }

  const handleCompanyEmailChange = value => {
    setCompanyDetailFormData({ ...companyDetailFormData, email: value })
  }

  const handleAddressChange = value => {
    setCompanyDetailFormData({ ...companyDetailFormData, address: value })
  }

  const handleWebsiteChange = value => {
    setCompanyDetailFormData({ ...companyDetailFormData, website: value })
  }

  const handlePhoneChange = value => {
    setCompanyDetailFormData({
      ...companyDetailFormData,
      phonenumber: value,
      phone: value,
    })
  }

  const handleLandlineChange = value => {
    setCompanyDetailFormData({ ...companyDetailFormData, landline: value })
  }

  const handleAboutChange = value => {
    setCompanyDetailFormData({ ...companyDetailFormData, about: value })
  }

  const handleFocus = () => {
    setErrors({})
  }

  const generateYear = sy => {
    let currentYear = new Date().getFullYear()
    const years = []
    let startYear = sy || process.env.GATSBY_MIN_YEAR_ESTABLISHMENT
    while (startYear <= currentYear) {
      years.push({ id: startYear, name: startYear, label: startYear })
      startYear++
    }
    return years
  }

  const handleYearChange = e => {
    setCompanyDetailFormData({
      ...companyDetailFormData,
      year_of_establishment: e?.id,
    })
  }

  const handleCountryChange = e => {
    setCompanyDetailFormData({
      ...companyDetailFormData,
      province: "",
      state: "",
      city: "",
      country: e?.id,
      country_value: e,
    })
  }

  const handleStateChange = e => {
    setCompanyDetailFormData({
      ...companyDetailFormData,
      city: "",
      province: e?.id,
      province_value: e,
      state_value: e,
      state: e?.id,
    })
  }

  const handleCityChange = e => {
    setCompanyDetailFormData({
      ...companyDetailFormData,
      city: e?.id,
      city_value: e,
    })
  }

  const handleNoOfEmployeesChange = e => {
    setCompanyDetailFormData({
      ...companyDetailFormData,
      no_of_employees: e,
    })
  }

  const handleIndustryChange = e => {
    setCompanyDetailFormData({
      ...companyDetailFormData,
      industry: e?.id,
    })
  }

  const handleSubmitAndSave = async () => {
    setIsProcessing(true)
    try {
      const res = COMPANY_DETAIL_FORM_SUBMIT(
        companyDetailFormData,
        setErrors,
        setIsProcessing,
        false,
        stateValue.state,
        stateValue.dispatch
      )
      return res
    } catch (error) { }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsProcessing(true)
    const res = await handleSubmitAndSave()
    if (res?.status === 200) {
      COMPANY_PROFILE_SUBMIT(
        setErrors,
        setIsProcessing,
        true,
        stateValue.dispatch,
        stateValue?.state
      )
    }
  }

  // const handleSubmitAndContinue = async () => {
  //   try {
  //     const res = COMPANY_DETAIL_FORM_SUBMIT(
  //       companyDetailFormData,
  //       setErrors,
  //       setIsProcessing,
  //       true,
  //       stateValue.state,
  //       stateValue.dispatch
  //     )
  //     return res
  //   } catch (error) {}
  // }

  const handleAvatarUpload = () => {
    return
  }

  useEffect(() => {
    COMPANY_COUNTRY_FETCH(companyDetailFormData, setState, setCity)
  }, [companyDetailFormData.country, companyDetailFormData.province]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form className="detail-form">
      <Row>
        <Col>
          <ProfileImageUploader
            formState={companyDetailFormData}
            setFormState={setCompanyDetailFormData}
            setErrors={setErrors}
            setProcessing={setIsProcessing}
            Upload={handleAvatarUpload}
            errors={errors}
            sub_text="Upload your company logo."
          />
        </Col>
      </Row>
      {!isLoading ? (
        <>
          <Row>
            <Col>
              <InputTextBox
                name="companyName"
                onFocus={handleFocus}
                class={errors?.organization_name && "error"}
                placeholder="Company Name"
                value={companyDetailFormData.organization_name}
                label={
                  <>
                    Company Name<span>*</span>
                  </>
                }
                onChange={handleCompanyNameChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <TagInput
                name="yearOfEstablishment"
                styles={` outline-faded`}
                error={errors?.year_of_establishment ? `error` : ""}
                options={generateYear(
                  process.env.GATSBY_MIN_YEAR_ESTABLISHMENT || 1985
                )}
                value={selectValueFromId(
                  generateYear(
                    process.env.GATSBY_MIN_YEAR_ESTABLISHMENT || 1985
                  ),
                  companyDetailFormData?.year_of_establishment
                )}
                onFocus={handleFocus}
                label={
                  <>
                    Year Of Establishment<span>*</span>
                  </>
                }
                placeholder={"Type and Select..."}
                useOnChange={handleYearChange}
                closeMenuOnSelect
                isClearable
              />
            </Col>
            <Col lg="4">
              <InputBox
                type="select"
                name="noOfEmployees"
                onFocus={handleFocus}
                onChange={handleNoOfEmployeesChange}
                styles={
                  errors?.num_of_employees
                    ? "rounded-border outline-faded error"
                    : "rounded-border outline-faded"
                }
                optionHeader="Select"
                key_value="display"
                value={companyDetailFormData.no_of_employees}
                options={configData.number_of_employees}
                label={
                  <>
                    No Of Employees<span>*</span>
                  </>
                }
              />
            </Col>
            <Col lg="4">
              <TagInput
                name="industry"
                styles={` outline-faded`}
                error={errors?.industry ? `error` : ""}
                options={configData?.industry}
                value={selectValueFromId(
                  configData?.industry,
                  companyDetailFormData?.industry
                )}
                onFocus={handleFocus}
                label={
                  <>
                    Industry<span>*</span>
                  </>
                }
                placeholder={"Type and Select..."}
                useOnChange={handleIndustryChange}
                closeMenuOnSelect
                isClearable
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <TagInput
                name="country"
                styles={` outline-faded`}
                error={errors?.country ? `error` : ""}
                options={configData.country}
                value={selectValueFromId(
                  configData?.country,
                  companyDetailFormData?.country
                )}
                onFocus={handleFocus}
                label={
                  <>
                    Country<span>*</span>
                  </>
                }
                placeholder={"Type and Select..."}
                useOnChange={handleCountryChange}
                disabled
                closeMenuOnSelect
                isClearable
              />
            </Col>
            <Col lg="6">
              <TagInput
                name="state"
                styles={` outline-faded`}
                error={errors?.state ? `error` : ""}
                options={state}
                value={selectValueFromId(state, companyDetailFormData?.state)}
                onFocus={handleFocus}
                disabled={!companyDetailFormData?.country}
                label={
                  <>
                    State<span>*</span>
                  </>
                }
                placeholder={"Type and Select..."}
                useOnChange={handleStateChange}
                closeMenuOnSelect
                isClearable
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <TagInput
                name="city"
                styles={`outline-faded`}
                error={errors?.city ? `error` : ""}
                options={city}
                value={selectValueFromId(city, companyDetailFormData?.city)}
                onFocus={handleFocus}
                disabled={!companyDetailFormData?.state}
                label={
                  <>
                    City<span>*</span>
                  </>
                }
                placeholder={"Type and Select..."}
                useOnChange={handleCityChange}
                closeMenuOnSelect
                isClearable
              />
            </Col>
            <Col lg="6">
              <InputTextBox
                name="companyName"
                onFocus={handleFocus}
                placeholder="Address"
                class={errors?.address && "error"}
                value={companyDetailFormData.address}
                label={
                  <>
                    Address<span>*</span>
                  </>
                }
                onChange={handleAddressChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InputTextBox
                name="email"
                onFocus={handleFocus}
                class={errors?.email && "error"}
                placeholder="Email Address"
                value={companyDetailFormData.email}
                disabled
                label={
                  <>
                    Email<span>*</span>
                  </>
                }
                onChange={handleCompanyEmailChange}
              />
            </Col>
            <Col lg="6">
              <InputTextBox
                name="website"
                onFocus={handleFocus}
                class={errors?.website && "error"}
                placeholder="Website"
                value={companyDetailFormData.website}
                label="Website"
                onChange={handleWebsiteChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InputTextBox
                name="phone"
                onFocus={handleFocus}
                class={errors?.phonenumber && "error"}
                placeholder="Mobile"
                value={companyDetailFormData.phonenumber}
                label={
                  <>
                    Mobile<span>*</span>
                  </>
                }
                onChange={handlePhoneChange}
              />
            </Col>
            <Col lg="6">
              <InputTextBox
                name="landline"
                onFocus={handleFocus}
                class={errors?.landline && "error"}
                placeholder="Phone/Landline"
                value={companyDetailFormData.landline}
                label={
                  <>
                    Phone/Landline<span>*</span>
                  </>
                }
                onChange={handleLandlineChange}

              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                autosize
                placeholder={`Write short description about your Company, the field of work.\n\nTell why Candidate(s) should choose your Company to be a part of.`}
                class={errors?.about && "error"}
                onChange={handleAboutChange}
                onFocus={handleFocus}
                value={companyDetailFormData.about}
                total={
                  stateValue?.state?.config?.about_field_length?.[0]
                    ?.text_field_length
                }
                label={
                  <>
                    About<span>*</span>
                  </>
                }
              />
            </Col>
          </Row>
          <Social />
          <Row>
            <Col>
              {errors &&
                Object.keys(errors)
                  .slice(0, 1)
                  .map((key, value) => {
                    if (companyDetailFormData.organization_name === "") {
                      return (
                        <div
                          key={value}
                          className="alert-block mb-4 d-flex align-items-center mt-2"
                        >
                          <img
                            src={Warning}
                            className="mr-3"
                            alt="wdn-warning"
                          />
                          {errors[key]}
                        </div>
                      )
                    } else if (
                      companyDetailFormData.year_of_establishment === null
                    ) {
                      return (
                        <div
                          key={value}
                          className="alert-block mb-4 d-flex align-items-center"
                        >
                          <img
                            src={Warning}
                            className="mr-3"
                            alt="wdn-warning"
                          />
                          Year of Establishment is required.
                        </div>
                      )
                    } else if (
                      companyDetailFormData.no_of_employees === "" ||
                      companyDetailFormData.industry === "" ||
                      companyDetailFormData.country === "" ||
                      companyDetailFormData.city === "" ||
                      companyDetailFormData.address === ""
                    ) {
                      return (
                        <div
                          key={value}
                          className="alert-block mb-4 d-flex align-items-center"
                        >
                          <img
                            src={Warning}
                            className="mr-3"
                            alt="wdn-warning"
                          />
                          {errors[key]}
                        </div>
                      )
                    } else if (
                      companyDetailFormData.email === "" ||
                      companyDetailFormData.phonenumber === "" ||
                      companyDetailFormData.about === ""
                    ) {
                      return (
                        <div
                          key={value}
                          className="alert-block mb-4 d-flex align-items-center"
                        >
                          <img
                            src={Warning}
                            className="mr-3"
                            alt="wdn-warning"
                          />
                          {errors[key]}
                        </div>
                      )
                    } else {
                      return (
                        <div
                          key={value}
                          className="alert-block mb-4 d-flex align-items-center"
                        >
                          <img
                            src={Warning}
                            className="mr-3"
                            alt="wdn-warning"
                          />
                          {errors[key]}
                        </div>
                      )
                    }
                  })}
            </Col>
          </Row>
        </>
      ) : (
        <Spinner />
      )}

      <Row>
        <Col lg={{ span: "3", offset: "9" }}>
          <button
            onClick={handleSubmit}
            type="submit"
            className="btn p-3 w-100 btn-custom rounded primary my-2"
            disabled={isProcessing}
          >
            {isProcessing ? "Processing" : "Submit"}
          </button>
        </Col>
      </Row>
    </form>
  )
}

export default CompanyDetailForm
